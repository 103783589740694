import React from 'react';
import createMuiTheme, { ThemeOptions, Theme } from '@material-ui/core/styles/createTheme';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { EdwardColors } from 'enums';
const defaultTheme = createMuiTheme();

export const borderRadiusButton = '18px';
export const borderRadiusBox = '6px';

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    status: {
      success: string;
    };
    borderRadius: {
      box: string;
    };
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    status?: {
      success?: string;
    };
    borderRadius?: {
      box?: string;
    };
  }
}

// key theme customizations
export const appThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: EdwardColors.DARK,
    },
    secondary: {
      main: EdwardColors.SECONDARY,
    },
    text: {
      primary: '#000',
      secondary: '#000',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 1024,
      lg: 1280,
      xl: 1920,
    },
  },
  shape: {
    borderRadius: 6,
  },
  borderRadius: {
    box: borderRadiusBox,
  },
  status: {
    success: green['500'],
  },
  // overrides of default props
  props: {
    MuiButton: {
      variant: 'outlined',
      disableElevation: true,
      disableRipple: true,
      disableTouchRipple: true,
      disableFocusRipple: true,
    },
    MuiTextField: {
      variant: 'outlined',
      fullWidth: true,
      autoComplete: 'off',
      size: 'small',
    },
    MuiFormControl: {
      size: 'small',
    },
    MuiGrid: {
      alignItems: 'flex-start',
      spacing: 2,
    },
    MuiDialog: {
      maxWidth: 'lg',
      fullWidth: true,
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        lineHeight: '1.7',
        paddingTop: '7px',
        borderRadius: borderRadiusButton,
        color: '#fff',
        backgroundColor: '#000',
        '&:hover': {
          backgroundColor: EdwardColors.GRAY_MEDIUM,
        },
        '&:active': {
          backgroundColor: EdwardColors.GRAY_LIGHTER,
        },
        '&.corners': {
          borderRadius: 0,
        },
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: EdwardColors.MEDIUM,
          color: '#000',
        },
        '&:active': {
          color: '#fff',
          backgroundColor: EdwardColors.SECONDARY,
        },
      },
      containedSecondary: {
        backgroundColor: EdwardColors.GRAY_MEDIUM,
        color: '#fff',
        '&:hover': {
          backgroundColor: EdwardColors.GRAY_LIGHTER,
        },
        '&:active': {
          color: '#fff',
          backgroundColor: EdwardColors.GRAY_LIGHT,
        },
        '&.secondaryGreen': {
          backgroundColor: EdwardColors.GREEN,
          '&:hover': {
            backgroundColor: EdwardColors.GREEN_LIGHTER,
          },
          '&:active': {
            color: '#fff',
            backgroundColor: '#000',
          },
        },
        '&.secondaryGold': {
          backgroundColor: EdwardColors.SECONDARY,
          '&:hover': {
            backgroundColor: EdwardColors.DARK,
          },
          '&:active': {
            color: '#fff',
            backgroundColor: '#000',
          },
        },
      },
      outlined: {
        lineHeight: '1.7',
        paddingTop: '6px',
        borderRadius: borderRadiusButton,
        borderWidth: '2px',
        '&.corners': {
          borderRadius: 0,
        },
        borderColor: EdwardColors.DARK,
        '& svg': {
          color: EdwardColors.DARK,
        },
        '&.Mui-disabled svg': {
          color: 'rgba(0, 0, 0, 0.26)'
        },
        '&:hover': {
          lineHeight: '1.7',
          paddingTop: '6px',
          backgroundColor: EdwardColors.DARK,
          color: '#fff',
          '& svg': {
            color: '#fff',
          },
        },
        '&:active': {
          lineHeight: '1.7',
          paddingTop: '6px',
          color: '#fff',
          backgroundColor: EdwardColors.SECONDARY,
          borderColor: EdwardColors.SECONDARY,
        },
        '& .MuiButton-label': {
          order: 1,
        },
      },
      outlinedPrimary: {
        lineHeight: '1.7',
        paddingTop: '6px',
        borderColor: EdwardColors.DARK,
        borderWidth: '2px',
        '&:hover': {
          lineHeight: '1.7',
          paddingTop: '6px',
          borderWidth: '2px',
          backgroundColor: EdwardColors.DARK,
          color: '#000',
          '& svg': {
            color: '#000',
          },
        },
        '&:active': {
          borderColor: EdwardColors.SECONDARY,
          backgroundColor: EdwardColors.SECONDARY,
        },
      },
      outlinedSecondary: {
        lineHeight: '1.7',
        paddingTop: '4px',
        borderWidth: '2px',
        borderColor: '#000',
        color: '#000',
        '&:hover': {
          paddingTop: '4px',
          borderWidth: '2px',
          borderColor: '#000',
          color: '#000',
          '& svg': {
            color: '#000',
          },
        },
        '& svg': {
          color: '#000',
        },
      },
    },
    MuiFormGroup: {
      root: {
        marginBottom: defaultTheme.spacing(2),
      },
    },
    MuiStepLabel: {
      root: {
        alignItems: 'center',
      },
      labelContainer: {
        alignItems: 'center',
        '& .MuiTypography-body2': {
          marginBottom: 0,
        },
      },
    },
    MuiStepIcon: {
      root: {
        color: 'black',
        '&$active': {
          color: EdwardColors.SECONDARY,
        },
      },
      text: {
        fill: 'white',
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'center',
        padding: defaultTheme.spacing(2,1)
      },
    },
    MuiFormLabel: {
      root: {
        color: defaultTheme.palette.text.primary,
      },
    },
    MuiTypography: {
      h1: {
        ...defaultTheme.typography.h4,
        fontSize: '28px', //1.5rem
        fontWeight: 800,
        marginBottom: defaultTheme.spacing(2),
      },
      h2: {
        ...defaultTheme.typography.h5,
        fontSize: '20px', // -
        fontWeight: 800,
        marginBottom: defaultTheme.spacing(1.2),
      },
      h3: {
        ...defaultTheme.typography.h6,
        fontSize: '18px', // -
        fontWeight: 800,
        marginBottom: defaultTheme.spacing(1),
      },
      h4: {
        fontSize: '14px', // 1rem
        fontWeight: 800, // 'bold'
        marginBottom: defaultTheme.spacing(0.5),
      },
      h5: {
        fontSize: '12px', // 1rem
        fontWeight: 800, // 'bold'
        marginBottom: defaultTheme.spacing(0.5),
      },
      /*
      h6: {
        fontSize: '1rem',
        fontWeight: 'bold',
        marginBottom: defaultTheme.spacing(0.5),
      },
      */
      body2: {
        lineHeight: 'normal',
        letterSpacing: 0,
        fontSize: 14,
        marginBottom: defaultTheme.spacing(1),
      },
    },
    MuiLink: {
      root: {
        color: EdwardColors.SECONDARY,
      },
    },
    MuiTextField: {
      root: {
        '& fieldset': { borderWidth: 2 },
      },
    },
    MuiFormControl: {
      root: {
        '& fieldset': { borderWidth: 2 },
      },
    },
    MuiInputLabel: {
      root: {
        // original mui color for palette.text.secondary
        color: 'rgba(0, 0, 0, 0.54)',
      },
      shrink: {
        color: '#000',
      },
    },
    MuiDialogContent: {
      root: {
        padding: defaultTheme.spacing(4),
        marginTop: -defaultTheme.spacing(1),
        [defaultTheme.breakpoints.down('xs')]: {
          padding: defaultTheme.spacing(2),
        },
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        maxHeight: `calc(100% - ${defaultTheme.spacing(10)}px)`,
        marginBottom: defaultTheme.spacing(7),
        marginTop: defaultTheme.spacing(3),
      },
      paperFullWidth: {
        [defaultTheme.breakpoints.down('xs')]: {
          width: `calc(100% - ${defaultTheme.spacing(2)}px)`,
          margin: defaultTheme.spacing(2),
        },
      },
    },
    MuiContainer: {
      maxWidthMd: {
        padding: 0,
      },
    },
  },
};

// theme generated from the theme customisations above
// so the overrides can acces theme values
export const appTheme: Theme = createMuiTheme(appThemeOptions);

export const AppTheme: React.FC = ({ children }) => <MuiThemeProvider theme={appTheme}>{children}</MuiThemeProvider>;
