import React, { Suspense, lazy } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import ReactDOM from 'react-dom';
import history from 'helpers/routerHistory';
import { Router, Route, Switch } from 'react-router-dom';
import './fonts/typeface-roboto/index.css';
import './fonts/Playfair/playfair.css';
import './style.css';
import { PDF_MICROSITE } from 'routes';
import FullscreenLoader from 'components/Loading/Fullscreen';

const MainApp = lazy(() => import('apps/Main'));
const PdfMicrosite = lazy(() => import('apps/Pdf/App'));

const app = (
  <Router history={history}>
    <Suspense fallback={<FullscreenLoader />}>
      <CssBaseline />
      <Switch>
        <Route path={PDF_MICROSITE} component={PdfMicrosite} />
        <MainApp />
      </Switch>
    </Suspense>
  </Router>
);

ReactDOM.render(app, document.getElementById('root'));
