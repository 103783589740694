import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { AppTheme } from 'components/AppTheme';

const Loading = () => (
  <AppTheme>
    <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
      <CircularProgress />
    </Box>
  </AppTheme>
);

export default Loading;
